import http from '../clients/Axios'

export default class StockOpnameService {
  async paginate(
    pageNumber,
    pageSize = 10,
    sortField,
    sortOrder,
    filters,
    sop_type = ''
  ) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === -1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          if (
            el === 'sop_no' ||
            el === 'whouse' ||
            el === 'assignee' ||
            el === 'sop_no'
          ) {
            filters_param =
              filters_param + '&' + el + '=' + filters[el].value.trim()
          } else {
            filters_param = filters_param + '&' + el + '=' + filters[el].value
          }
        }
      })
    }

    let url = ''
    if (sop_type === '') {
      url = `/stockopname/paginate?page=${pageNumber}&size=${pageSize}&sort=${sort}${filters_param}`
    } else {
      url = `/stockopname/paginate?page=${pageNumber}&size=${pageSize}&sort=${sort}${filters_param}&sop_type=${sop_type}`
    }

    const res = await http.get(url)

    return res.data
  }

  async get() {
    const res = await http.get('/stockopname')
    return res
  }

  async getBy(param) {
    param = param ? param : ''
    const res = await http.get(`/stockopname/receive${param}`)
    return res
  }

  async getById(param) {
    param = param ? param : ''
    const res = await http.get(`/stockopname/${param}`)
    return res
  }

  async loadCSVItem(json) {
    const res = await http.post('/stockopname/itemcsv', json)
    return res
    // const res = await http.get(`/stockopname/itemcsv?json=${json}`)
    // return res
  }

  async loadCategoryItem(ctg_id) {
    const res = await http.get(`/stockopname/category?ctg_id=${ctg_id}`)
    return res
  }

  async add(form) {
    const res = await http.post('/stockopname', form)
    return res
  }

  async update(id, form) {
    const res = await http.put(`/stockopname/${id}`, form)
    return res
  }

  async delete(stockopname) {
    const res = await http.delete(`/stockopname/${stockopname}`)
    return res
  }

  async print(id) {
    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/stockopname/${id}/print?t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
}
